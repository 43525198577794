import React from 'react'

import { Link } from 'gatsby'

import cs from 'utils/classes'
import useUrlPrefix from 'hooks/useUrlPrefix'

import Arrow from 'components/common/Arrow/Arrow'

import CaseTop from '../CaseTop'

import getRandomDummy from '../getRandomImage'

import css from './CaseItemWide.module.scss'

const CaseItemWide = ({ goal, image, brands, slug, title, className }) => {
  const urlPrefix = useUrlPrefix()
  const resImage = image?.length > 0 ? image : getRandomDummy()
  return <Link to={urlPrefix(`/cases/${slug}`)} className={cs(css.wrapper, className)}>
    <div className={css.image} style={{ backgroundImage: `url(${resImage})` }}>
      <CaseTop goal={goal} brands={brands} className={css.top} />
    </div>
    <div className={css.body}>
      <p>{title}</p>
      <Arrow color={'orange'} size={'big'} className={css.arrow} />
    </div>
  </Link>
}

export default CaseItemWide
