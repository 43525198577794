import React from "react"

import { navigate } from "gatsby"

import PageCases from "components/PageCases/PageCases"

import Layout from "components/Layout/Layout"

import useChangeLanguage from "hooks/useChangeLanguage"

const Cases = ({ pageContext, location }) => {
  const { page, cases, lang } = pageContext
  useChangeLanguage(lang)
  if (!pageContext || !pageContext.page) {
    navigate("page/1/")
    return null
  }
  return (
    <Layout>
      <PageCases page={page} cases={cases} location={location} />
    </Layout>
  )
}

export default Cases
