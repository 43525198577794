import { useStaticQuery, graphql } from 'gatsby'

import { useTranslation } from 'react-i18next'

export const useFormats = () => {
  const { i18n: { language } } = useTranslation()

  const { brandsRu, brandsEn } = useStaticQuery(
    graphql`
      query BrandsQuery {
        brandsRu {
          results {
            id
            title
            icon
          }
        }
        brandsEn {
          results {
            id
            title
            icon
          }
        }
      }
    `
  )

  if (language === 'en') return brandsEn
  return brandsRu
}

export default useFormats
