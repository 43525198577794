import React, { useEffect, useState } from "react"

import { navigate } from "@reach/router"
import isEqual from "lodash/isEqual"

import cs from "utils/classes"

import { useTranslation } from "react-i18next"
import { useStore } from "effector-react"

import casesStore from "state/casesStore"

import useGoals from "hooks/useGoals"
import useBrands from "hooks/useBrands"
import useContents from "hooks/useContents"
import useUrlPrefix from "hooks/useUrlPrefix"

import Seo from "components/seo"

import PageLayout from "components/common/PageLayout"
import Subscription from "../PageArticles/Subscription/Subscription"

import CasesList from "./CasesList/CasesList"
import Filters from "./Filters/Filters"

import { filteredStore, setFiltered, filterStore, setFilter } from "./filter"

import css from "./PageCases.module.scss"

const CASES_IMAGE = require("./assets/cases.png")

const PageCases = ({ page = 1, cases, location }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const contents = useContents()
  const urlPrefix = useUrlPrefix()

  const goals = useGoals()
  const brands = useBrands()

  const filtered = useStore(filteredStore)
  const filter = useStore(filterStore)

  const [inited, setInited] = useState(false)

  useEffect(() => {
    if (parseInt(page) === 1 || !inited) return

    navigate(urlPrefix("/cases/page/1"))
  }, [filter])

  useEffect(() => {
    setInited(true)
  }, [])

  useEffect(() => {
    const objFilter = {
      forceNoClear: true,
      [`translated_${language}`]: true,
      ...filter,
      page,
    }

    casesStore.fetchEffect(objFilter)
  }, [filter, page])

  useEffect(() => {
    if (inited) setFiltered(true)
  }, [filter])

  const casesFront = useStore(casesStore.store)
  const casesList = filtered ? casesFront : cases

  return (
    <>
      <Seo
        title={cases.metaTitle}
        description={cases.metaDescription}
        keywords={cases.metaKeywords}
      />
      <PageLayout
        title={contents.casesTitle || t("cases_title")}
        image={CASES_IMAGE}
      >
        <div
          className={cs(
            css.container,
            filtered && casesFront.isLoading && css.loading
          )}
        >
          <Filters
            goals={goals}
            brands={brands}
            initial={location?.state?.filter}
            cb={value => {
              if (!isEqual(value, filter)) setFilter(value)
            }}
          />
          <CasesList page={page} cases={casesList} />
        </div>
      </PageLayout>
      <Subscription />
    </>
  )
}

export default PageCases
