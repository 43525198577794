import React from "react"

import css from "./PageLayout.module.scss"

const PageLayout = ({ title, children, image }) => {
  return (
    <>
      <div className={css.container}>
        <h1 className={css.title}>{title}</h1>
      </div>
      {image && (
        <div
          className={css.image}
          style={{ backgroundImage: `url(${image})` }}
        />
      )}

      <div className={css.body}>{children}</div>
    </>
  )
}

export default PageLayout
