import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useStore } from 'effector-react'

import cs from 'utils/classes'
import filterCallback from 'utils/filterCallback'

import MenuButton from 'components/common/MenuButton/MenuButton'
import SelectButton from 'components/common/SelectButton/SelectButton'
import Accordion from 'components/common/Accordion/Accordion'
import PopupFilters from 'components/common/PopupFilters/PopupFilters'

import {
  yearStore, setYear,
  filterGoalsStore, setFilterGoals,
  filterBrandsStore, setFilterBrands,
} from '../filter'

import css from './Filters.module.scss'

const Filters = ({ goals, brands, cases, initial, cb }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: '(max-width: 650px)' })

  const year = useStore(yearStore)
  const filterGoals = useStore(filterGoalsStore)
  const filterBrands = useStore(filterBrandsStore)

  useEffect(() => {
    if (initial?.goal) setFilterGoals([initial.goal])
  }, [initial])

  useEffect(() => {
    if (!cb) return

    const filter = {}
    if (year) filter['pub_date__year'] = year
    if (filterGoals.length > 0) filter['goal__id__in'] = filterGoals.join(',')
    if (filterBrands.length > 0) filter['brands__id__in'] = filterBrands.join(',')

    cb(filter)
  }, [filterGoals, filterBrands, year])

  const cbGoals = filterCallback(filterGoals, setFilterGoals)
  const cbBrands = filterCallback(filterBrands, setFilterBrands)

  const goalsFilter = (
    <div className={css.filter}>
      <p className={css.filterTitle}>{t('Goals')}</p>
      <div className={css.listButtons}>
        {goals.results.map(item =>
          <MenuButton
            checked={filterGoals.indexOf(item.id) > -1}
            key={item.id}
            cb={(value) => cbGoals(item.id, value)}
          >
            {item.name}
          </MenuButton>)}
      </div>
    </div>
  )

  const yearFilter = (
    <div className={cs(css.filter, css.select)}>
      <SelectButton cb={setYear} value={year} />
    </div>
  )

  const brandFilters = brands.results.map(item =>
    <MenuButton
      checked={filterBrands.includes(item.id)}
      className={css.button}
      key={item.id}
      cb={(value) => cbBrands(item.id, value)}
    >
      {item.title}
    </MenuButton>
  )

  return <>
    {!isMobile && <>
      <div className={css.filters}>{goalsFilter}{yearFilter}</div>
      {brands.results.length > 0 && (
        <Accordion className={css.accordion} title={t('brands_filter_title')}>
          <div className={css.filterButtons}>
            {brandFilters}
          </div>
        </Accordion>
      )}
    </>}
    {isMobile &&
      <PopupFilters>
        {yearFilter}
        {goalsFilter}
        <div className={css.filter}>
          <p className={css.filterTitle}>{t('brands_filter_title')}</p>
          <div className={css.listButtons}>
            {brandFilters}
          </div>
        </div>
      </PopupFilters>
    }
  </>
}

export default Filters
