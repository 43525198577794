import React from 'react'

import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import pluralize from 'utils/pluralize'

import Pagination from 'components/common/Pagination/Pagination'

import useUrlPrefix from 'hooks/useUrlPrefix'

import CaseItemWide from 'components/common/Cases/CaseItemWide'

import css from './CasesList.module.scss'

const CasesList = ({ page, cases }) => {
  const { t, i18n: { language } } = useTranslation()

  const urlPrefix = useUrlPrefix()
  const isMobile = useMediaQuery({ query: '(max-width: 650px)' })

  const russianFound = pluralize(cases.count, ['Найден', 'Найдено', 'Найдено'])
  const russianCount = pluralize(cases.count, ['кейс', 'кейса', 'кейсов'])

  return <>
    <div className={css.found}>
      {language === 'ru' ? russianFound : t('Found')} {cases.count} {language === 'ru' ? russianCount : t('cases_found_count')}
    </div>
    <div className={css.list}>
      {cases.results.map(item => <CaseItemWide {...item} className={css.item} key={item.id} />)}
    </div>
    {cases.pagesCount > 1 && <Pagination
      basePath={urlPrefix('/cases')}
      pagesCount={cases.pagesCount}
      page={parseInt(page)}
      countToShow={isMobile ? 1 : 3}
    />}
  </>
}

export default CasesList
