import { createStore, createEvent } from 'effector'

const setYear = createEvent()
const yearStore = createStore(null)
  .on(setYear, (state, payload) => payload)

const setFilterGoals = createEvent()
const filterGoalsStore = createStore([])
  .on(setFilterGoals, (state, payload) => payload)

const setFilterBrands = createEvent()
const filterBrandsStore = createStore([])
  .on(setFilterBrands, (state, payload) => payload)

const setFiltered = createEvent()
const filteredStore = createStore(false)
  .on(setFiltered, (state, payload) => payload)

const setFilter = createEvent()
const filterStore = createStore({})
  .on(setFilter, (state, payload) => payload)

export {
  setYear, yearStore,

  setFilterGoals, filterGoalsStore,

  setFilterBrands, filterBrandsStore,

  setFiltered, filteredStore,

  setFilter, filterStore,
}
