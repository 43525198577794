import React from "react"
import pt from "prop-types"
import classes from "utils/classes"

import css from "./Arrow.module.scss"

const Arrow = ({ color = "black", size = "normal", className }) => (
  <svg
    className={classes(css.arrow, css[color], css[size], className)}
    width="102"
    height="27"
    viewBox="0 0 102 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="13" width="101" height="1" fill="white" />
    <path className={"arrow"} d="M92 1L101.048 13.5L92 26" stroke="white" />
  </svg>
)

Arrow.propTypes = {
  color: pt.oneOf(["black", "white", "orange", "silver"]),
  size: pt.oneOf(["normal", "big", "large"]),
}

export default Arrow
